import React, {useEffect} from "react";
import {Loading, Text, View} from "react-native-web-ui-components";
import {Platform, StyleSheet} from "react-native";
import {useHistory} from "../routing";
import {useLibrary} from "../modules/Library";
import ScreenTitle from "../components/ScreenTitle";
import BooksList from "../components/BooksList";
import {AnnouncementBanner} from "../components/AnnouncementBanner";

const LibraryScreen = props => {
    const {library, isLoading, isError} = useLibrary()
    const history = useHistory()

    useEffect(() => {
        if (!isLoading && library.length === 0) {
            history.push('/raamatud')
        }
    }, [library])

    if (isLoading) return <Loading />
    if (isError) return <View><Text>Viga andmete laadimisel</Text></View> // TODO: error reporting

    return library.length ? (
        <View style={styles.container}>
            <AnnouncementBanner/>
            <View style={styles.gallery}>
                <ScreenTitle>Minu raamatud</ScreenTitle>
                <BooksList books={library}/>
            </View>
        </View>
    ) : null
}

export default LibraryScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        padding: 25,
    },
    gallery: {
        flex: 1,
        paddingHorizontal: Platform.OS === 'web' ? 25 : 0,
        paddingBottom: 110,
    },
})
