import React, {useEffect, useRef, useState} from "react";
import {Loading, View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import AudioPlayer from "../components/AudioPlayer";
import {PLAYER_CLOSE} from "../redux/actions";
import {selectDlUrl} from "../redux/config/configSelectors";
import {useLibrary} from "../modules/Library";

function AudioPlayerScreen(props) {
    const id = parseInt(props.player.bookId)
    const [loading, setLoading] = useState(true)
    const books = useSelector(state => state.books);
    const {library, isLoading, mutateLibrary} = useLibrary();
    const user = useSelector(state => state.user);
    const [book, setBook] = useState();
    const dlUrl = useSelector(selectDlUrl);
    const links = useRef([]);
    const dispatch = useDispatch()
    const {
        volume = 1,
        rate = 1,
    } = props.player
    const {
        index = 0,
        position = 0,
    } = (props.player.positions && props.player.positions[book?.id]) ? props.player.positions[book?.id] : {}
    const savedProps = {volume, rate, index, position}

    useEffect(() => {
        if (!isLoading) {
            setBook(library.find(b => b.id === id) || books.find(b => b.id === id));
        }
    }, [id, user, isLoading]);

    useEffect(() => {
        if (!book || !loading) {
            return;
        }
        axios.get(process.env.API2_URL + "/books/" + book.id + "/files")
          .then(res => {
              links.current = res.data.files.map(path => `${dlUrl}/books/${book.id}/${path}?ticket=${res.data.ticket}`);
              setLoading(false);
          })
          .catch(error => {
              dispatch({
                  type: PLAYER_CLOSE,
              });
              throw (error);
          })
    }, [book]);

    return (loading || isLoading) ? <Loading /> : (
        <View style={styles.container}>
            <AudioPlayer
                apiToken={user ? user.apiToken : null}
                headers={{Authorization: axios.defaults.headers.common['Authorization']}}
                links={links.current}
                author={book.authorFirstName + " " + book.authorLastName}
                title={book.title}
                description={book.description}
                imageUri={book.image}
                bookId={book.id}
                {...savedProps}
            />
        </View>
    )
}

export default AudioPlayerScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
})
