import React, {useState} from "react";
import {StyleSheet} from "react-native";
import {connect, useDispatch, useSelector} from "react-redux";
import {Hr, Text, TextInput, View} from "react-native-web-ui-components";
import Button from "../components/Button";
import {selectUser} from "../redux/reducers";
import {useDimensions} from "react-native-responsive-ui/src";
import {useFormStyles} from "../components/forms/styles";
import axios from "axios";
import {SET_USER_DATA} from "../redux/actions";
import {showMessage} from "react-native-flash-message";

const GiftCardScreen = props => {
    const [activationCode, setActivationCode] = useState();
    const dispatch = useDispatch();
    const styles = useStyles();
    const GIFT_CARD_SCREEN_TEXT_SLUG = "kinkekaart";
    const staticContent = props.pages.find(page => page.identifier === GIFT_CARD_SCREEN_TEXT_SLUG);
    const details = useSelector(selectUser);
    const formStyles = useFormStyles();

    const renderPurchaseOption = (duration) => {
        const destinationPath = details ? `/kingituse-maksmine/${duration}` : "/login";
        return (
            <View style={styles.purchaseOption}>
                <Text style={styles.optionDurationLabel}>{duration} {duration === 1 ? "kuu" : "kuud"}</Text>
                <Hr marginTop={8} marginBottom={16} color={"black"}/>
                <Text style={styles.optionLabel}>Digireadi pakett</Text>
                <Text style={styles.priceLabel}>{duration * 6.99}€</Text>
                <Button to={destinationPath} type={"dark"}>Osta</Button>
            </View>
        );
    }

    const activate = () => {
        if (activationCode) {
            axios.post(process.env.API2_URL + "/activate-gift-card", {
                activationCode: activationCode,
            }).then(({data}) => {
                setActivationCode(undefined);
                dispatch({
                    type: SET_USER_DATA,
                    payload: data,
                });
                showMessage({
                    message: 'Kinkekaart on aktiveeritud',
                    type: 'success',
                });

            }).catch(e => {
                if (e.response) {
                    console.log(e.response.status);
                    switch (e.response.status) {
                        case 404:
                            showMessage({
                                message: 'Kinkekaarti ei leitud',
                                type: 'danger',
                            });
                            break;
                        case 401:
                            showMessage({
                                message: 'Kinkekaart on juba aktiveeritud',
                                type: 'danger',
                            });
                            break;
                        default:
                            showMessage({
                                message: 'Kinkekaardi aktiveerimine ebaõnnestus',
                                type: 'danger',
                            });
                    }
                }
            });
        }
    }

    return (
        <View style={styles.container}>
            <Text style={styles.sectionTitle}>Osta kinkekaart</Text>
            <View style={styles.purchaseContainer}>
                {renderPurchaseOption(1)}
                {renderPurchaseOption(3)}
                {renderPurchaseOption(6)}
                {renderPurchaseOption(12)}
            </View>
            <View style={styles.centeredContainer}>
                <Text style={styles.codeActivationDescription}>Kinkekaardi ostmiseks pead olema sisse logitud</Text>
            </View>

            <Text style={styles.sectionTitle}>Aktiveeri kinkekaart</Text>

            <View style={styles.codeActivationContainer}>
                <View style={styles.codeActivationText}>
                    <Text style={styles.codeActivationLabel}>Soovid kinkekaarti lunastada?</Text>
                    <Text style={styles.codeActivationDescription}>{details
                        ? "Sisesta oma kinkekood siia, et see aktiveerida."
                        : " Kinkekaardi aktiveerimiseks pead olema sisse logitud."}
                    </Text>
                </View>
                <View style={styles.codeActivationForm}>
                    <View style={formStyles.inputContainer}>
                        <TextInput
                            autoFocus
                            style={formStyles.input}
                            focusStyle={[formStyles.input, formStyles.inputFocus]}
                            placeholder="Kinkekood"
                            onChangeText={activationCode => setActivationCode(activationCode)}
                            blurOnSubmit={false}
                            disabled={!details}
                        />
                    </View>
                    <Button
                        type="dark"
                        style={styles.codeActivationFormButton}
                        hoverStyle={styles.buttonHover}
                        textStyle={styles.buttonText}
                        textHoverStyle={styles.buttonTextHover}
                        onPress={activate}
                        disabled={!details}
                    >
                        Aktiveeri
                    </Button>
                </View>
            </View>

            {staticContent ? (
                <React.Fragment>
                    <View style={styles.container}>
                        <View style={styles.content}>
                            <div dangerouslySetInnerHTML={{__html: staticContent.content}}/>
                        </View>
                    </View>
                </React.Fragment>
            ) : null}
        </View>
    );
}

const mapStateToProps = ({pages}) => ({pages})

export default connect(mapStateToProps)(GiftCardScreen)

const useStyles = () => {
    const {width} = useDimensions()
    const isWide = width > 800
    return StyleSheet.create({
        container: {
            flex: 1,
            padding: 50,
        },
        purchaseContainer: {
            display: "flex",
            flexDirection: isWide ? "row" : "column",
            justifyContent: "center",
            alignContent: "center",
        },
        sectionTitle: {
            marginTop: isWide ? 40 : 20,
            fontFamily: "Aino-Headline",
            fontSize: 24,
            display: "flex",
            justifyContent: "center"
        },
        codeActivationContainer: {
            margin: "auto",
            display: "flex",
            flexDirection: isWide ? "row" : "column",
            justifyContent: "space-evenly",
            alignContent: "center",
            backgroundColor: "rgba(241,241,241,0.15)",
            borderStyle: "solid",
            borderColor: "#6161FF",
            borderWidth: 1,
            borderRadius: 8,
            padding: 30,
            marginTop: 10,
            maxWidth: 1440,
            width: isWide ? "auto" : "100%"
        },
        codeActivationText: {
            marginTop: 20,
            marginRight: 20,
        },
        codeActivationFormButton: {
            width: "100%",
            marginTop: "2.5px"
        },
        codeActivationLabel: {
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 8,
            width: "auto"
        },
        codeActivationDescription: {
            fontSize: 14,
            marginBottom: 8,
            width: "auto"
        },
        codeActivationForm: {
            display: "flex",
            flexDirection: isWide ? "row" : "column",
            alignContent: "center",
            justifyContent: "center",
            marginTop: 20
        },
        purchaseOption: {
            width: isWide ? "20%" : "100%",
            maxWidth: isWide ? 440 : undefined,
            minWidth: 220,
            padding: 30,
            marginHorizontal: isWide ? 25 : 0,
            marginVertical: isWide ? 10 : 20,
            borderStyle: "solid",
            borderColor: "black",
            borderWidth: 1,
            textAlign: "center",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(241,241,241,0.15)"
        },
        optionDurationLabel: {
            color: "#6161FF",
            fontSize: 24,
            marginBottom: 4,
            fontWeight: "bold"
        },
        optionLabel: {
            fontSize: 18,
            marginBottom: 8
        },
        priceLabel: {
            fontSize: 18,
            fontWeight: "bold",
            marginBottom: 8
        },
        centeredContainer: {
            justifyContent: "center",
            alignItems: "center",
        },
    })
};


