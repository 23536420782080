import React from 'react';
import {connect} from 'react-redux';
import {useParams} from "../routing";
import {View} from "react-native-web-ui-components";
import {StyleSheet} from "react-native";

const PageScreen = props => {
    const {slug} = useParams()
    const page = props.pages.find(page => page.identifier === slug)
    return page ? (
        <React.Fragment>
            <View style={styles.container}>
                <View style={styles.content}>
                    <div dangerouslySetInnerHTML={{__html: page.content}} />
                </View>
            </View>
        </React.Fragment>
    ) : null // TODO: 404
}

const mapStateToProps = ({pages}) => ({pages})

export default connect(mapStateToProps)(PageScreen)

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 50,
    },
    content: {
        paddingBottom: 20,
        paddingTop: 20,
        // whiteSpace: 'pre-wrap'
    },
})
