import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MENU_CLOSE, MENU_OPEN} from "../../redux/actions";
import {useLogout} from "../../modules/User";
import {Brand} from "../Brand";
import {TOP_MENU_HEIGHT} from "../../constants";
import Link from "../Link";
import {Text, View} from "react-native-web-ui-components";
import {useHistory} from "../../routing";
import {Entypo} from "@expo/vector-icons";
import {selectCartBooks} from "../../redux/cart/cartSelectors";
import {useLocation} from "../../routing.web";

function HamburgerMenu() {
    const menuOpen = useSelector(state => state.isMenuOpen)
    const user = useSelector(state => state.user)
    const logout = useLogout()
    const dispatch = useDispatch()
    const history = useHistory()
    const menuClick = () => dispatch({type: menuOpen ? MENU_CLOSE : MENU_OPEN})
    const linkClick = () => dispatch({type: MENU_CLOSE})
    useEffect(() => () => menuOpen ? dispatch({type: MENU_CLOSE}) : null)
    const location = useLocation();
    const styles = {
        container: {
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: '99',
            opacity: 0.9,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: 'white',
            width: '100%',
            color: 'black',
            height: TOP_MENU_HEIGHT,
            padding: '0 40px',
            boxSizing: 'border-box',
        },
        logo: {
            margin: '0 auto',
        },
        rightSide: {
            display: 'flex',
            width: '77'
        },
    }

    const menu = [
        {
            title: 'Avaleht',
            to: '/',
        },
        {
            title: 'Raamatud',
            to: '/raamatud',
        },
        {
            title: 'Kingitus',
            to: '/kingitus',
        },
        /*{
            title: 'Otsi',
            to: '/otsing',
        },*/
    ]
    if (user) {
        menu.push({
            title: 'Ostukorv',
            to: '/ostukorv'
        });
        menu.push({
            title: 'Konto',
            to: '/konto'
        });
        menu.push({
            title: 'Logi välja',
            to: '/logout',
            onPress: logout,
        });
    } else {
        menu.push({
            title: 'Logi sisse',
            to: '/login',
        })
        menu.push({
            title: 'Loo konto',
            to: '/registreerumine',
        })
    }
    const menuItems = menu.map((item, index) => (
        <MenuItem
            key={index}
            delay={`${index * 0.1}s`}
            to={item.to}
            onPress={() => {
                linkClick()
                if (item.onPress) item.onPress()
            }}>
            {item.title}
        </MenuItem>
    ))
    const onPress = () => {
        linkClick()
        history.push('/')
    }

    return (
        <div>
            <div style={styles.container}>
                <Link to="/" onPress={onPress} className="no-decoration">
                    <Brand/>
                </Link>
                <div style={styles.rightSide}>
                    {location.pathname !== '/ostukorv' && <ShoppingCartButton user={user}/>}
                    <MenuButton open={menuOpen} onClick={menuClick}/>
                </div>
            </div>
            <Menu open={menuOpen}>
                {menuItems}
            </Menu>
        </div>
    )
}

function MenuItem(props) {
    const styles = {
        container: {
            opacity: 0,
            animation: '1s appear forwards',
            animationDelay: props.delay,
        },
        menuItem: {
            flex: 1,
            cursor: 'pointer',
            animation: '0.5s slideIn forwards',
            animationDelay: props.delay,
            width: '100%',
            paddingVertical: '1rem',
            marginHorizontal: 60,
        },
        menuItemText: {
            fontFamily: 'Aino-Regular',
            fontSize: '1.2rem',
            lineHeight: '1.5rem',
            color: 'black',
            transition: 'color 0.2s ease-in-out',
        },
        menuItemTextHover: {
            color: 'gray',
        },
        line: {
            width: '90%',
            height: 1,
            background: 'gray',
            margin: '0 auto',
            animation: '0.5s shrink forwards',
            animationDelay: props.delay,
        }
    }
    const history = useHistory()
    const onPress = () => {
        props.to && history.push(props.to)
        props.onPress && props.onPress()
    }

    return (
        <View style={styles.container}>
            <Link
                to={props.to}
                style={styles.menuItem}
                textStyle={styles.menuItemText}
                textHoverStyle={styles.menuItemTextHover}
                onPress={onPress}
            >
                {props.children}
            </Link>
            <View style={styles.line}/>
        </View>
    )
}

function Menu(props) {
    const [open, setOpen] = useState(props.open || false)

    const styles = {
        container: {
            position: 'fixed',
            top: 0,
            left: 0,
            height: open ? '100%' : 0,
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            opacity: 0.95,
            transition: 'height 0.3s ease',
            zIndex: 2,
        },
        menuList: {
            paddingTop: TOP_MENU_HEIGHT,
            position: 'relative',
            height: '100%',
        },
    }

    useEffect(() => {
        if (open !== props.open) {
            setOpen(props.open)
        }
    })

    return (
        <div style={styles.container}>
            {open ? <div style={styles.menuList}>{props.children}</div> : null}
        </div>
    )
}

function ShoppingCartButton(props) {
    const cartContents = useSelector(selectCartBooks);
    const isVisible = !!props.user;

    const styles = {
        container: {
            height: 36,
            width: 36,
            display: 'flex',
            flexDirection: !!cartContents.length ? 'column' : 'row',
            alignItems: 'center',
            cursor: 'pointer',
            margin: 5
        },
        count: {
            fontWeight: 'bold',
            color: 'black',
            textAlign: 'center'
        },
        countContainer: {
            position: 'relative',
            height: 15,
            width: 15,
            borderRadius: 5,
            backgroundColor: 'rgba(95,195,125,0.8)',
            left: 5,
            bottom: 5,
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 2000,
        }
    }

    return (
        <div style={styles.container}>
            {isVisible && <Link draggable="false" to="/ostukorv">
                {!!cartContents.length && <View style={styles.countContainer}>
                    <Text style={styles.count}>{cartContents.length}</Text>
                </View>}
                <Entypo name="shopping-cart" size={24} color="black"/>
            </Link>}
        </div>
    )
}


function MenuButton(props) {
    const [open, setOpen] = useState(!!props.open)

    const color = props.color || 'black'
    const styles = {
        container: {
            height: 36,
            width: 36,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        line: {
            height: 4,
            width: 24,
            background: color,
            transition: 'all 0.2s ease',
        },
        lineTop: {
            transform: open ? 'rotate(-45deg)' : 'none',
            transformOrigin: 'top right',
            marginBottom: 5,
        },
        lineMiddle: {
            opacity: open ? 0 : 1,
            transform: open ? 'translateX(16px)' : 'none',
        },
        lineBottom: {
            transform: open ? 'translateX(3px) translateY(-1px) rotate(45deg)' : 'none',
            transformOrigin: 'top right',
            marginTop: 5,
        },
    }

    useEffect(() => {
        if (open !== props.open) {
            setOpen(props.open)
        }
    })

    return (
        <div style={styles.container} onClick={props.onClick ? props.onClick : () => setOpen(!open)}>
            <div style={{...styles.line, ...styles.lineTop}}/>
            <div style={{...styles.line, ...styles.lineMiddle}}/>
            <div style={{...styles.line, ...styles.lineBottom}}/>
        </div>
    )
}

export default HamburgerMenu

