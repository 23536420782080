import React, {useEffect, useState} from "react";
import {Image, Text, View} from "react-native-web-ui-components";
import {useDispatch, useSelector} from "react-redux";
import {StyleSheet} from "react-native";
import Button from "../components/Button";
import PaymentProxy from "../components/PaymentProxy";
import {Mixpanel} from "../utils/mixpanel";
import {useHistory, useParams} from "../routing";
import axios from "axios";
import {SET_USER_DATA} from "../redux/actions";
import {selectUser} from "../redux/reducers";
import {Entypo, Ionicons} from "@expo/vector-icons";


const creditCardImage = {uri: require('../assets/images/credit_card.svg')};

export const CreditCardPaymentScreen = () => {
    const [startPayment, setStartPayment] = useState(false);
    const [user, setUser] = useState(false);
    const [subscriptionType, setSubscriptionType] = useState("20H_LIMITED");
    const details = useSelector(selectUser);
    const dispatch = useDispatch();
    const {endpoint, token} = useParams();
    const history = useHistory();

    const isCardChange = endpoint === "change-card-details";
    const isTrialPayment = endpoint === "start-trial";
    const userToken = token === "web" ? details?.apiToken : token;
    const subscriptionPrice = subscriptionType === "20H_LIMITED" ? '6.99' : "11.99";

    if (!userToken) {
        history.push("/login", {redirect: `/kaardimakse/${endpoint}/${token}`});
    }

    const freePaymentInfo = {
        name: "Kaardiandmete uuendamine",
        recurringTitle: "Kaardiandmete uuendamine.",
        recurringDescription: "Kaardiandmete uuendamiseks teostame 0€ makse."
    };

    const startTrialInfo = {
        name: "Prooviperioodi alustamine",
        recurringTitle: "Prooviperioodi alustamine",
        recurringDescription: `Prooviperioodiga alustamiseks teostame 0€ makse. Edaspidi maksab pakett ${subscriptionPrice}€/kuus.`
    };

    const paymentInfo = {
        name: "Digiread liikmetasu",
        recurringTitle: "Digiread rakenduse liikmetasu",
        recurringDescription: `Liikmetasu on ${subscriptionPrice}€ kuus.`
    };

    useEffect(() => {
        if (token !== "web" && token) {
            // A precaution for app users since another account could be logged in the web
            axios.get(process.env.API2_URL + '/user', {headers: {"Api-Token": userToken}})
                .then(res => {
                    dispatch({
                        type: SET_USER_DATA,
                        payload: res.data,
                    })
                })
        }
    }, []);

    const initiatePayment = (type) => {
        setSubscriptionType(type);
        axios.get(process.env.API2_URL + '/user', {headers: {"Api-Token": userToken}})
            .then(({data}) => {
                dispatch({
                    type: SET_USER_DATA,
                    payload: data,
                });
                Mixpanel.track("Subscription payment initiated");
                setUser(data);
                setStartPayment(true);
            });
    };

    const getPaymentInfo = () => {
        return isCardChange ? freePaymentInfo : isTrialPayment ? startTrialInfo : paymentInfo;
    }

    const title = isTrialPayment ? "Prooviperioodi alustamine" :
        isCardChange ? "Kaardiandmete muutmine" : "Kuupaketiga liitumine";

    const subtitle = isTrialPayment ? "Vali pakett ja tee 0€ makse, millega alustad oma kahenädalast prooviperioodi." :
        isCardChange ? "Tegemist on 0€ maksega, millega saate muuta oma kaardiandmeid." : "Vali sobiv pakett ja alusta raamatute kuulamist alates 6.99€ eest kuus.";

    return (
        <React.Fragment>
            <Text style={styles.title}>
                <h1>
                    {title}
                </h1>
            </Text>

            <Text style={styles.subTitle}>
                {subtitle}
            </Text>

            <View style={styles.container}>
                <View style={styles.infoBox}>
                    {!isCardChange &&
                        <View style={styles.cardTitleRow}>
                            <Ionicons name="time-outline" size={48} color="#0000f0"/>
                            <Text style={styles.cardTitle}>Paindlik pakett</Text>
                        </View>
                    }
                    {isCardChange && (
                        <Image
                            fixed
                            style={styles.optionImage}
                            source={creditCardImage}
                        />
                    )}
                    {!isCardChange && <Text style={styles.cardText}>
                        <Entypo name="check" size={8} color="black"/> 6.99€/kuu
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Isiklik konto
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Kuula
                        20 tundi kuus
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Tühista
                        etteteatamiseta
                        <br/><br/>
                    </Text>}

                    <Button type="dark" onPress={() => initiatePayment("20H_LIMITED")}>
                        {isCardChange || isTrialPayment ? "Sisesta kaardiandmed" : "Liitu paketiga"}
                    </Button>
                </View>
                {!isCardChange && <View style={styles.infoBox}>
                    <View style={styles.cardTitleRow}>
                        <Ionicons name="infinite-outline" size={48} color="#0000f0"/>
                        <Text style={styles.cardTitle}>Piiramatu pakett</Text>
                    </View>
                    <Text style={styles.cardText}>
                        <Entypo name="check" size={8} color="black"/> 11.99€/kuu
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Isiklik konto
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Kuula
                        piiramatult
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Tühista
                        etteteatamiseta
                        <br/><br/>
                    </Text>
                    <Button type="dark" onPress={() => initiatePayment("UNLIMITED")}>
                        {isCardChange || isTrialPayment ? "Sisesta kaardiandmed" : "Liitu paketiga"}
                    </Button>
                </View>}
            </View>

            <PaymentProxy
                start={startPayment}
                user={user}
                endpoint={endpoint}
                subscriptionType={subscriptionType}
                paymentInfo={getPaymentInfo()}
            />
        </React.Fragment>
    );
}

const styles = StyleSheet.create({
    cardTitle: {
        fontFamily: "Aino-Headline",
        fontSize: 24,
        display: "flex",
        justifyContent: "center",
    },
    cardTitleRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginTop: 8,
        alignItems: "center",
        gap: 14,
        marginBottom: 16
    },
    title: {
        margin: 8,
        textAlign: "center",
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.5em',
        lineHeight: 36
    },
    subTitle: {
        fontSize: '1.2em',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
    },
    container: {
        display: 'flex',
        flexWrap: "wrap",
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoBox: {
        minHeight: 300,
        borderWidth: '0.125em',
        margin: '3.25em',
        paddingBottom: '1.5em',
        paddingHorizontal: '2.5em',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20
    },
    cardText: {
        fontSize: '1em',
    },
    optionImage: {
        width: '200px',
        height: 'auto',
        paddingTop: '1.5em',
        paddingBottom: '0.5em'
    },
})
