import {SET_CLOSED_ANNOUNCEMENTS} from "../actions";

const defaultState = null;

export default function announcementReducer(state = defaultState, action) {
    switch (action.type) {
        case SET_CLOSED_ANNOUNCEMENTS:
            return {...action.payload};
        default:
            return state;
    }
}
