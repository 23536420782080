import React from "react";
import {StyleSheet} from "react-native";
import {Link, Text, View} from "react-native-web-ui-components";
import {useSelector} from "react-redux";
import {Brand} from "../Brand";
import Button from "../Button";
import {useLogout} from "../../modules/User";
import {selectCartCount} from "../../redux/cart/cartSelectors";
import {selectUserSubscribed} from "../../redux/reducers";
import {Mixpanel} from "../../utils/mixpanel";

export default function Menu() {
    const user = useSelector(state => state.user);
    const logout = useLogout();
    const cartCount = useSelector(selectCartCount);

    /**
     * @todo Refactor: extract component
     * @todo Implement subscription expiry logic
     */

    const handleLogout = () => {
        Mixpanel.track("Logout");
        Mixpanel.reset();
        logout();
    };

    const RightMenu = () => user ? (
      <>
          <View key="gift" style={styles.menuItem}>
              <MenuButton to="/kingitus">
                  Kingitus
              </MenuButton>
          </View>
          <View style={styles.menuItem}>
              <MenuButton to="/konto">
                  Konto
              </MenuButton>
          </View>
          <View style={styles.menuItem}>
              <MenuButton to="/ostukorv">
                  {"Ostukorv" + (cartCount > 0 ? ` (${cartCount})` : "")}
              </MenuButton>
          </View>
          <View style={styles.menuItem}>
              <MenuButton to="/logout" onPress={handleLogout}>
                  Logi välja
              </MenuButton>
          </View>
      </>
    ) : <>
        <View key="gift" style={styles.menuItem}>
            <MenuButton to="/kingitus">
                Kingitus
            </MenuButton>
        </View>
        <View key="login" style={styles.menuItem}>
            <MenuButton to="/login">
                Logi sisse
            </MenuButton>
        </View>
        <View key="registration" style={styles.menuItem}>
            <MenuButton to="/registreerumine">
                Loo konto
            </MenuButton>
        </View>
    </>;

    return (
        <View style={styles.container}>
            <View style={styles.menuItem}>
                <Link to="/" className="no-decoration">
                    <Brand/>
                </Link>
            </View>
            <View style={styles.menuItem}>
                <MenuButton to="/raamatud">
                    Raamatud
                </MenuButton>
            </View>

            <View style={styles.rightMenuContainer}>
                <RightMenu />
            </View>
        </View>
    );
}

const MenuButton = props => {
    const {noBorder, noPadding, ...rest} = props
    const style = [
        styles.menuButton,
        noBorder ? styles.noBorder : styles.empty,
        noPadding ? styles.noPadding : styles.empty,
    ]
    return (
        <Button
            type="dark"
            style={style}
            hoverStyle={styles.menuButtonHover}
            textStyle={styles.menuButtonText}
            textHoverStyle={styles.menuButtonTextHover}
            {...rest}
        />
    )
}

const styles = StyleSheet.create({
    empty: {},
    container: {
        paddingVertical: 0,
        paddingHorizontal: 40,
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        height: '100%',
    },
    noBorder: {
        borderWidth: 0,
    },
    noPadding: {
        paddingHorizontal: 0,
        paddingVertical: 0,
    },
    menuItem: {
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    menuButton: {
        padding: 0,
        margin: 0,
    },
    menuButtonHover: {

    },
    menuButtonText: {
        fontSize: 16,
        fontFamily: 'Aino-Bold',
    },
    menuButtonTextHover: {

    },
    rightMenuContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    bookTokenCounter: {
        lineHeight: 18
    }
});
