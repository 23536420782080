import {Text, View} from "react-native-web-ui-components";
import {useDispatch, useSelector} from "react-redux";
import {StyleSheet} from "react-native";
import React from "react";
import Button from "./Button";
import {SET_CLOSED_ANNOUNCEMENTS} from "../redux/actions";
import Link from "../components/Link";

export const AnnouncementBanner = () => {
    const announcementSection = useSelector(state => state.blocks.announcement);
    const dispatch = useDispatch();
    const closedAnnouncement = useSelector(state => state.announcement);

    if (closedAnnouncement && closedAnnouncement.updatedAt === announcementSection?.updatedAt) {
        return null;
    }

    const onClose = () => {
        if (announcementSection) {
            dispatch({
                type: SET_CLOSED_ANNOUNCEMENTS,
                payload: announcementSection,
            });
        }
    }

    return (
        <View>
            {announcementSection !== undefined && announcementSection.title !== "" && announcementSection.content !== "" &&
                <View style={styles.container}>
                    <Text style={styles.heading}>{announcementSection.title}</Text>
                    <Text style={styles.text}>{announcementSection.content}</Text>
                    {announcementSection.linkedPageSlug && <Link to={"/" + announcementSection.linkedPageSlug}
                                                                 style={styles.textLink}>{announcementSection.linkText}</Link>}
                    <Button onPress={onClose}>
                        Sulge
                    </Button>
                </View>
            }
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        maxWidth: 1080,
        backgroundColor: '#540F8A',
        minHeight: 200,
        borderBottomLeftRadius: 15,
        borderBottomRightRadius: 15,
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 20,
        paddingBottom: 30,
        marginBottom: 20,
        display: "flex",
        justifyContent: "space-between",
        alignSelf: "center"
    },
    heading: {
        color: "white",
        fontSize: 24,
        fontWeight: "500",
        marginBottom: 15,
        fontFamily: 'Aino-Headline',
    },
    text: {
        color: "white",
        fontSize: 20,
        fontWeight: "500",
        marginBottom: 15,
    },
    textLink: {
        color: "white",
        textDecorationLine: "underline",
        fontSize: 20,
        fontWeight: "500",
        marginBottom: 15,
    },
});