import React, {useEffect} from 'react';
import {Text, View} from "react-native-web-ui-components";
import {useDispatch} from "react-redux";
import {StyleSheet} from "react-native";
import Button from "../components/Button";
import PaymentProxy from "../components/PaymentProxy";
import {Mixpanel} from "../utils/mixpanel";
import {Entypo, Ionicons} from "@expo/vector-icons";
import axios from "axios";
import {useHistory} from "../routing";
import {useUser} from "../modules/User";
import {SET_USER_DATA} from "../redux/actions";
import {useParams} from "../routing.web";


export const SubscriptionChangeScreen = () => {
    const [startPayment, setStartPayment] = React.useState(false);
    const [subscriptionType, setSubscriptionType] = React.useState("20H_LIMITED");
    const history = useHistory();
    const user = useUser();
    const {token} = useParams();
    const dispatch = useDispatch();
    const userToken = token === "web" ? user?.apiToken : token;

    useEffect(() => {
        if (user.isLoggedIn === true || !userToken) {
            return;
        }
        axios.get(process.env.API2_URL + '/user', {headers: {"Api-Token": userToken}})
            .then(res => {
                dispatch({
                    type: SET_USER_DATA,
                    payload: res.data,
                })
            })
    }, []);

    const hide20hLimited = user.subscriptionType === "20H_LIMITED";
    const hideUnlimited = user.subscriptionType === "UNLIMITED";

    const _startPayment = (type) => {
        setSubscriptionType(type);
        Mixpanel.track("Subscription change initiated");
        setStartPayment(true);
    }

    const _startDowngrade = (type) => {
        Mixpanel.track("Subscription change initiated");
        axios.post(process.env.API2_URL + "/change-subscription", {
            subscriptionType: type,
        },{headers: {"Api-Token": userToken}}).then(() => {
            axios.get(process.env.API2_URL + '/user', {headers: {"Api-Token": userToken}})
                .then(res => {
                    dispatch({
                        type: SET_USER_DATA,
                        payload: res.data,
                    })
                })
            history.push("/subscription-payment-complete");
        });
    }

    if (user.isLoggedIn === true && (!user.subscriptionActive || user.subscriptionType === null)) {
        history.push("/paketiost");
    }

    return (
        <React.Fragment>
            <Text style={styles.title}>
                <h1>
                    Kuupaketi vahetus
                </h1>
            </Text>

            <Text style={styles.subTitle}>
                {hideUnlimited ? "Vali uus pakett. Hetkel käimasolev piiramatu pakett kehtib makseperioodi lõpuni. Pärast seda algab uus pakett."
                    : "Vali uus pakett. Teostame puuduoleva summa osas makse ning pärast makse laekumist saad kohe piiramatus koguses raamatuid kuulata."}
            </Text>
            <View style={styles.container}>
                {hideUnlimited && <View style={styles.infoBox}>

                    <View style={styles.cardTitleRow}>
                        <Ionicons name="time-outline" size={48} color="#0000f0"/>
                        <Text style={styles.cardTitle}>Paindlik pakett</Text>
                    </View>

                    <Text style={styles.cardText}>
                        <Entypo name="check" size={8} color="black"/> 6.99€/kuu
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Isiklik konto
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Kuula
                        20 tundi kuus
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Tühista
                        etteteatamiseta
                        <br/><br/>
                    </Text>

                    <Button type="dark" onPress={() => _startDowngrade("20H_LIMITED")}
                            disabled={hide20hLimited}>
                        Vali pakett
                    </Button>
                </View>}
                {hide20hLimited && <View style={styles.infoBox}>
                    <View style={styles.cardTitleRow}>
                        <Ionicons name="infinite-outline" size={48} color="#0000f0"/>
                        <Text style={styles.cardTitle}>Piiramatu pakett</Text>
                    </View>
                    <Text style={styles.cardText}>
                        <Entypo name="check" size={8} color="black"/> 11.99€/kuu
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Isiklik konto
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Kuula
                        piiramatult
                        <br/><br/>
                        <Entypo name="check" size={8} color="black"/> Tühista
                        etteteatamiseta
                        <br/><br/>
                    </Text>
                    <Button type="dark" onPress={() => _startPayment("UNLIMITED")}
                            disabled={hideUnlimited}>
                        Vali pakett
                    </Button>
                </View>}
            </View>
            <PaymentProxy start={startPayment} user={user}
                          endpoint={"change-subscription"}
                          subscriptionType={subscriptionType}/>
        </React.Fragment>
    )

}

const styles = StyleSheet.create({
    cardTitle: {
        fontFamily: "Aino-Headline",
        fontSize:
            24,
        display:
            "flex",
        justifyContent:
            "center",
    }
    ,
    cardTitleRow: {
        display: "flex",
        flexDirection:
            "row",
        justifyContent:
            "space-evenly",
        marginTop:
            8,
        alignItems:
            "center",
        gap:
            14,
        marginBottom:
            16
    }
    ,
    title: {
        margin: 8,
        textAlign:
            "center",
        display:
            'flex',
        flexDirection:
            'column',
        fontSize:
            '1.5em',
        lineHeight:
            36
    }
    ,
    subTitle: {
        margin: 8,
        fontSize:
            '1.2em',
        textAlign:
            'center',
        display:
            'flex',
        flexDirection:
            'column',
    }
    ,
    container: {
        display: 'flex',
        flexWrap:
            "wrap",
        flex:
            1,
        flexDirection:
            'row',
        justifyContent:
            'center',
        alignItems:
            'center',
    }
    ,
    infoBox: {
        minHeight: 300,
        borderWidth:
            '0.125em',
        margin:
            '3.25em',
        paddingBottom:
            '1.5em',
        paddingHorizontal:
            '2.5em',
        justifyContent:
            'space-between',
        alignItems:
            'center',
        borderTopLeftRadius:
            20,
        borderTopRightRadius:
            20
    }
    ,
    callToActionContainer: {
        paddingHorizontal: '1.5em',
        paddingBottom:
            '0.625em',
    }
    ,
    cardText: {
        fontSize: '1em',
    }
    ,
    optionImage: {
        width: '200px',
        height:
            'auto',
        paddingTop:
            '1.5em',
        paddingBottom:
            '0.5em'
    }
    ,
})
